import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import LayoutTwo from "../components/layoutTwo"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <LayoutTwo>
        <div className="layout-padding-top"></div>
        <div className="error-page-wrapper d-flex align-items-center">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={8} className="text-center">
                        <h1>Something went wrong!</h1>
                        <p>We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Link to="/contact" className="button button-primary button-with-icon"><i className="icon icon-banner-valuation"></i>Contact us</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/" className="button button-primary-light button-with-icon"><i className="icon-valuation-home"></i>Home page</Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    </LayoutTwo>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
